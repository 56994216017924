import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

const queryString = require("query-string");

function UpdateDetail() {
    let id = null;

    if (typeof window !== "undefined") {
        var parsed = queryString.parse(location.search);
        if (typeof parsed.id !== "undefined") {
            id = parsed.id.replace(/\/$/, "");
        }
    }

    // Fetch update detail
    const [Page, setPage] = useState([]);

    useEffect(() => {
        fetch(
            `${process.env.GATSBY_NGORDER_APP_URL}/API/update_app_detail?id=${id}`
        )
            .then((response) => response.json())
            .then((resultData) => {
                setPage(resultData.data[0]);
            });
    }, []);

    return (
        <section className="bg-secondary dark:bg-gray-800 h-100 min-h-screen flex items-center w-full md:py-10">
            <Link
                to="/"
                state={{
                    modal: false,
                    noScroll: true,
                }}
                className="right-1 top-1 fixed w-8 h-8 text-gray-400"
            >
                <svg viewBox="0 0 40 40">
                    <path
                        fill="currentColor"
                        d="M31.6 10.7L22.3 20l9.3 9.3-2.3 2.3-9.3-9.3-9.3 9.3-2.3-2.3 9.3-9.3-9.3-9.3 2.3-2.3 9.3 9.3 9.3-9.3z"
                    />
                </svg>
            </Link>

            <div className="max-w-4xl w-full mx-auto h-auto p-6 rounded-xl shadow-xl bg-white dark:bg-gray-700">
                <img
                    src={Page.img}
                    alt="info image"
                    className="w-full rounded-lg"
                />
                <h1 className="text-xl md:text-2xl leading-tight font-display mb-6 mt-5 pr-1 sm:pr-3 md:pr-6 dark:text-gray-200">
                    {Page.title}
                </h1>
                <div
                    className="text-lg md:text-xl text-gray-600 dark:text-gray-400 leading-tight pr-1 sm:pr-3 md:pr-6 content-detail"
                    dangerouslySetInnerHTML={{ __html: Page.content }}
                />
            </div>
        </section>
    );
}

export default UpdateDetail;
